.App__Header {
  position: relative;
}

.App__Footer {
  position: relative;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
  }
}

.Logo {
  display: flex;
  gap: 30px;
  align-items: center;

  &__Caption {
    font-size: 12px;
    font-weight: 500;
    color: currentColor;
    opacity: 0.60;
    letter-spacing: 0;
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
}

.Headline {
  --lh: 90px;
  opacity: 0.84;
  position: absolute;
  width: 100%;
  height: calc(42px * 4);
  margin: 0;

  display: grid;
  grid-template-rows: 1fr;

  font-size: var(--lh);
  font-weight: 900;
  letter-spacing: 0;
  text-transform: uppercase;
  &__Word {
    position: absolute;
    width: 100%;
    top: calc(var(--index) * var(--lh));
    height: var(--lh);
    line-height: var(--lh);
    overflow: hidden;
    &__Content {
      position: absolute;
    }
  }

  @media screen and (max-width: 1280px) {
    --lh: 72px;
  }

  @media screen and (max-width: 720px) {
    --lh: 42px;
  }

  @media screen and (max-width: 480px) {
    --lh: 36px;
  }
}

.Link {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding: 12px 18px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.12) 100%);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  transition: all 300ms ease;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    border-radius: inherit;
    background: rgba(0, 0, 0, 0);
    transition: all 300ms ease;
  }

  &:hover {
    &::after {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}
