.Layout {
  min-height: 100vh;
  position: relative;
  z-index: 6;

  display: grid;
  grid-template-rows: 1fr auto;

  max-width: 960px;
  margin: 0 auto;
  padding: 90px 0;

  @media screen and (max-width: 1280px) {
    padding: 60px;
  }

  @media screen and (max-width: 480px) {
    padding: 60px 30px;
  }
}

.Canvas {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  opacity: 1;
}
